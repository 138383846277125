:root {
  --dark-blue : #193b56;
  --orange: #e9a224;
  --pink: #F0AB99;
  --red: #FE4A49;
  --teal: #008080;

  --REQUESTED: #235EFF;
  --UNDER_REVIEW: #EE964B;
  --ACCEPTED: #04724D;
  
  --DENIED: #E93820;
  
  --UNLISTED: var(--orange);
  --PRIVATE: var(--dark-blue);
  --PUBLIC: var(--green);

  --green: #04724D;
  --green-hover: #025136;

  --blue: #1f50da;
  --lightblue: #7091EB;
  --primary: #235EFF;
  --blue-hover: #24408c;

  --yellow: #EE964B;
  --yellow-hover: #b4733a;

  --gray: #ababab;
}

body {
  min-height: 100vh;
  margin: 0;
  display: flex;
}

.App {
  align-items: center;
  justify-content: center;
}