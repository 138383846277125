.card {
    /*box-shadow: black 0px 0px 1vh 0vh;*/
    border: 2em;
    /*border-radius: 1em;
    margin: 1vh;*/
    padding: 1em;
    background-color: rgb(255, 255, 255);
    display:flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card input:focus {
    border: none;
}


.innerCard {
    border: #ebebeb solid .1em;
    border-radius: 1em;
    padding: .5vh;
    background-color: rgb(255, 255, 255);
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}