.titles {
    display:flex;
    flex-direction: row;
    width: 100%;
    font-size: 2vh;
    padding: 0.5em;
    justify-content: space-around;
}
.background {
    background-image: url("../assets/images/background_1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: calc(100vh);
    overflow: hidden;

}

.authContainer {
    width: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}